import React from "react";
import { connect } from 'react-redux';
import RootTable from "./RootTable";
import moment from "moment";
import { deleteActivatedAppStart } from 'store/routers/routersActions';

const ActivateAppsTable = (props) => {

  const { items, deleteActivatedAppStart } = props
  const { currentUser } = props.auth
  const { router } = props.routers

  const handleDelete = (os, app_version) => {

    const payload = {
      token: currentUser.token,      
      mac: router.detail.macaddr1,
      os,
      app_version
    }

    deleteActivatedAppStart(payload)
  }

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">OS</th>
          <th scope="col">App version</th>
          <th scope="col">Created</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((item, key) => {
            return(
              <tr key={ key }>
                <td className="py-2 align-middle">{ item.os }</td>
                <td className="py-2 align-middle">{ item.app_version }</td>                
                <td className="py-2 align-middle">{ moment(item.created_at).format('lll') }</td>
                <td className="py-2 align-middle">
                  <button 
                    type="button" 
                    className="btn btn-danger btn-sm"
                    onClick={ () => handleDelete(item.os, item.app_version) }
                  >
                    {
                      router.activatedAppDeleting?                      
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:
                      'Delete'
                    }
                  </button>
                </td>
              </tr>  
            )
          })
        }       
      </tbody>      
    </RootTable>
  );
}

const mapStateToProps = state => ({
  auth: state.auth, 
  routers: state.routers 
})

const mapDispatchToProps = {
  deleteActivatedAppStart
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAppsTable)
