import types from './routersActionTypes';

export const InitialState = () => ({
  type: types.INITIAL_STATE,
});

export const removeRoutersStart = () => ({
  type: types.REMOVE_ROUTERS_START
});

export const removeStuckRoutersStart = () => ({
  type: types.REMOVE_STUCK_ROUTERS_START
});

export const getRouterDetailStart = (payload) => ({
  type: types.GET_ROUTER_DETAIL_START,
  payload,
});

export const getRouterDetailSuccess = (user) => ({
  type: types.GET_ROUTER_DETAIL_SUCCESS,
  payload: user,
});

export const getRouterDetailFailure = (error) => ({
  type: types.GET_ROUTER_DETAIL_FAILURE,
  payload: error,
});

export const updateWifiSettingsStart = (payload) => ({
  type: types.UPDATE_WIFI_SETTINGS_START,
  payload
});

export const updateWifiSettingsSuccess = (user) => ({
  type: types.UPDATE_WIFI_SETTINGS_SUCCESS,
  payload: user,
});

export const updateWifiSettingsFailure = (error) => ({
  type: types.UPDATE_WIFI_SETTINGS_FAILURE,
  payload: error,
});

export const addAppCodeStart = (payload) => ({
  type: types.ADD_APP_CODES_START,
  payload
});

export const addAppCodeSuccess = (user) => ({
  type: types.ADD_APP_CODES_SUCCESS,
  payload: user,
});

export const addAppCodeFailure = (error) => ({
  type: types.ADD_APP_CODES_FAILURE,
  payload: error,
});

export const updateRouterActivatedStart = (payload) => ({
  type: types.UPDATE_ROUTER_ACTIVATED_START,
  payload
});

export const updateRouterActivatedSuccess = (user) => ({
  type: types.UPDATE_ROUTER_ACTIVATED_SUCCESS,
  payload: user,
});

export const updateRouterActivatedFailure = (error) => ({
  type: types.UPDATE_ROUTER_ACTIVATED_FAILURE,
  payload: error,
});

export const searchRoutersStart = (payload) => ({
  type: types.SEARCH_ROUTERS_START,
  payload
});

export const searchRoutersSuccess = (data) => ({
  type: types.SEARCH_ROUTERS_SUCCESS,
  payload: data,
});

export const searchRoutersFailure = (error) => ({
  type: types.SEARCH_ROUTERS_FAILURE,
  payload: error,
});

export const searchStuckRoutersStart = (payload) => ({
  type: types.SEARCH_STUCK_ROUTERS_START,
  payload
});

export const searchStuckRoutersSuccess = (data) => ({
  type: types.SEARCH_STUCK_ROUTERS_SUCCESS,
  payload: data,
});

export const searchStuckRoutersFailure = (error) => ({
  type: types.SEARCH_STUCK_ROUTERS_FAILURE,
  payload: error,
});

export const addMeshStart = (payload) => ({
  type: types.ADD_MESH_START,
  payload
});

export const addMeshSuccess = (data) => ({
  type: types.ADD_MESH_SUCCESS,
  payload: data,
});

export const addMeshFailure = (error) => ({
  type: types.ADD_MESH_FAILURE,
  payload: error,
});

export const deleteMeshStart = (payload) => ({
  type: types.DELETE_MESH_START,
  payload,
});

export const deleteMeshSuccess = (data) => ({
  type: types.DELETE_MESH_SUCCESS,
  payload: data,
});

export const deleteMeshFailure = (error) => ({
  type: types.DELETE_MESH_FAILURE,
  payload: error,
})

export const addDomainStart = (payload) => ({
  type: types.ADD_DOMAIN_START,
  payload: payload,
});

export const addDomainSuccess = (data) => ({
  type: types.ADD_DOMAIN_SUCCESS,
  payload: data,
});

export const addDomainFailure = (error) => ({
  type: types.ADD_APP_CODES_FAILURE,
  payload: error,
});

export const deleteDomainStart = (payload) => ({
  type: types.DELETE_DOMAIN_START,
  payload: payload,
});

export const deleteDomainSuccess = (data) => ({
  type: types.DELETE_DOMAIN_SUCCESS,
  payload: data,
});

export const deleteDomainFailure = (error) => ({
  type: types.DELETE_DOMAIN_FAILURE,
  payload: error,
});

export const updateVpnStatusStart = (payload) => ({
  type: types.UPDATE_VPN_STATUS_START,
  payload: payload,
});

export const updateVpnStatusSuccess = (data) => ({
  type: types.UPDATE_VPN_STATUS_SUCCESS,
  payload: data,
});

export const updateVpnStatusFailure = (error) => ({
  type: types.UPDATE_VPN_STATUS_FAILURE,
  payload: error,
});

export const updateVpnLocationStart = (payload) => ({
  type: types.UPDATE_VPN_LOCATION_START,
  payload: payload,
});

export const updateVpnLocationSuccess = (data) => ({
  type: types.UPDATE_VPN_LOCATION_SUCCESS,
  payload: data,
});

export const updateVpnLocationFailure = (error) => ({
  type: types.UPDATE_VPN_LOCATION_FAILURE,
  payload: error,
});

export const getDevicesStart = (payload) => ({
  type: types.GET_DEVICES_START,
  payload: payload,
});

export const getDevicesSuccess = (data) => ({
  type: types.GET_DEVICES_SUCCESS,
  payload: data,
});

export const getDevicesFailure = (error) => ({
  type: types.GET_DEVICES_FAILURE,
  payload: error,
});

export const rebootStart = (payload) => ({
  type: types.REBOOT_START,
  payload: payload,
});

export const rebootSuccess = (data) => ({
  type: types.REBOOT_SUCCESS,
  payload: data,
});

export const rebootFailure = (error) => ({
  type: types.REBOOT_FAILURE,
  payload: error,
});

export const subscriptionUpdateStart = (payload) => ({
  type: types.SUBSCRIPTION_UPDATE_START,
  payload: payload,
});

export const subscriptionUpdateSuccess = (data) => ({
  type: types.SUBSCRIPTION_UPDATE_SUCCESS,
  payload: data,
});

export const subscriptionUpdateFailure = (error) => ({
  type: types.SUBSCRIPTION_UPDATE_FAILURE,
  payload: error,
});

export const deleteActivatedAppStart = (payload) => ({
  type: types.DELETE_ACTIVATED_APP_START,
  payload: payload,
});

export const deleteActivatedAppSuccess = (data) => ({
  type: types.DELETE_ACTIVATED_APP_SUCCESS,
  payload: data,
});

export const deleteActivatedAppFailure = (error) => ({
  type: types.DELETE_ACTIVATED_APP_FAILURE,
  payload: error,
});
