import React from "react";
import moment from "moment";
import RootTable from "./RootTable";

const AppCodesTable = (props) => {

  const { items } = props

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + "h ": "";
    var mDisplay = m > 0 ? m + "m " : "";
    var sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay; 
  }

  return (
    <RootTable style={{ maxHeight: 200, overflow: "auto", display: 'inline-block'}}>
      <thead>
        <tr>
          <th scope="col">Code</th>
          <th scope="col">Intercome ID</th>
          <th scope="col">Created</th>
          <th scope="col">Expires</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((item, key) => {
            return(
              <tr key={ key }>
                <td className="py-3 align-middle">{ item.code }</td>
                <td className="py-3 align-middle"></td>                
                <td className="py-3 align-middle">{ moment(item.created_time).format('lll') }</td>
                <td className="py-3 align-middle">{ secondsToHms(item.expire_time) }</td>
              </tr>  
            )
          })
        }       
      </tbody>
    </RootTable>
  );
}

export default AppCodesTable;