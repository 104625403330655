import React, { useState } from "react";
import { connect } from 'react-redux';
import { addAppCodeStart } from 'store/routers/routersActions';

const AddAppCodesCard = (props) => {

    const { addAppCodeStart } = props
    const { currentUser } = props.auth
    const { router } = props.routers
    
    const handleSubmit = (e) => {
        e.preventDefault()

        // if(!validate()) {
        //     return
        // }

        let code = Math.floor(100000 + Math.random() * 900000)

        const payload = {
            token: currentUser.token,
            mac: router.detail.macaddr1,
            code: code.toString(),
            expires: 3600
        }
        addAppCodeStart(payload)
    }

    return (
        <div className="card">
            <form onSubmit={handleSubmit}>
                <div className="card-header">
                    Add App Codes
                </div>
                <div className="card-footer d-flex">
                    <button 
                        type="submit" 
                        className="btn btn-primary btn-sm"
                    >
                        Add App Code
                    </button>
                </div>
            </form>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    routers: state.routers   
})
  
const mapDispatchToProps = {
    addAppCodeStart
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AddAppCodesCard)
