import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerStart } from "../store/actions";
import RoleSelector from "./AccountsTable/components/Role/components/RoleSelector";
import { ROLE_SYS_ADMIN } from "utils/roles";

const defaultUser = {name: "", email: "", password: "", role: ROLE_SYS_ADMIN };

const validate = credentials => !(credentials.name === "" || credentials.email === "" || credentials.password === "");

export default () => {
  const [credentials, setCredentials] = useState(defaultUser);
  const { currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { users: items } = useSelector(state => state.users);

  useEffect(() => {
    setCredentials(defaultUser);
  }, [items]);

  const handleSubmit = (e) => {
    e.preventDefault()

    if(!validate(credentials)) {
      return
    }
    
    const { email, ...params } = credentials;
    dispatch(registerStart(currentUser.token, {...params, login: email}));
  }

  const handleChange = e => setCredentials({ ...credentials, [e.target.name]: e.target.value });

  return (
        <div className="card">
          <div className="card-header">
            Create account
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mt-1">
                <label className="form-label fs-7 fw-bold">Name</label>
                <input 
                  name="name"
                  type="text" 
                  className="form-control" 
                  placeholder="" 
                  onChange={handleChange}   
                  value={credentials.name}
                />
              </div>              
              <div className="mt-1">
                <label className="form-label fs-7 fw-bold">Email</label>
                <input 
                  name="email"
                  type="text" 
                  className="form-control" 
                  placeholder="" 
                  onChange={handleChange}   
                  value={credentials.email}
                />
              </div>
              <div className="mt-1">
                <label className="form-label fs-7 fw-bold">Role/Position</label>
                <RoleSelector {...{onChange:handleChange, value: credentials.role}} />
              </div>
              <div className="mt-1">
                <label className="form-label fs-7 fw-bold">Password</label>
                <input 
                  name="password"
                  type="text" 
                  className="form-control" 
                  placeholder="" 
                  onChange={handleChange}   
                  value={credentials.password}
                />
              </div>
              <button type="submit" className="btn btn-secondary mt-3">
                Create account
              </button>
            </form>
          </div>
        </div> 
  );
}