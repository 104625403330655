import React from "react";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faArrowRightFromBracket,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from "react-router-dom"
import { logOut } from '../store/auth/authActions';
import { InitialState } from '../store/routers/routersActions';
import { ROLE_SYS_ADMIN, ROLE_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_T2_SUPPORT_AGENT, ROLE_T1_SALES_AGENT } from "utils/roles";
import useAccessLevel from "utils/useAccessLevel";
const SideBar = (props) => {

  const { show, logOut, InitialState } = props
  const { currentUser } = props.auth  
  const navigate = useNavigate();
  const accesslevel = useAccessLevel();

  const handleClickLogout = () => {   
    logOut();
    InitialState()
    navigate("/")
  }

  let title = null
  let navItems = null
  let userName = null

  if(currentUser) {
    userName = currentUser.email

    if(accesslevel === ROLE_SYS_ADMIN) { // System Administrator
      title = 'System Administrator'
      navItems = <>
                  <Nav.Item>
                    <Nav.Link href="/dashboard">
                      <FontAwesomeIcon icon={faHome} className="me-2" />
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>
  
                  <Nav.Item>
                    <Nav.Link href="/routers">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers
                    </Nav.Link>
                  </Nav.Item>
  
                  <Nav.Item>
                    <Nav.Link href="/routers-stuck">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers stuck
                    </Nav.Link>
                  </Nav.Item>
  
                  <Nav.Item>
                    <Nav.Link href="/users">
                      <FontAwesomeIcon icon={faUser} className="me-2" />
                      Accounts
                    </Nav.Link>
                  </Nav.Item>
                </>
    } else if(accesslevel === ROLE_ADMIN) { // Administrator
      title = 'Administrator'
      navItems = <>
                  <Nav.Item>
                    <Nav.Link href="/users">
                      <FontAwesomeIcon icon={faUser} className="me-2" />
                      Accounts
                    </Nav.Link>
                  </Nav.Item>
                </>
    } else if(accesslevel === ROLE_SUPPORT_ADMIN) { // Support Administrator
      title = 'Support Administrator'
      navItems = <>
                  <Nav.Item>
                    <Nav.Link href="/dashboard">
                      <FontAwesomeIcon icon={faHome} className="me-2" />
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>
  
                  <Nav.Item>
                    <Nav.Link href="/routers">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers
                    </Nav.Link>
                  </Nav.Item>
  
                  <Nav.Item>
                    <Nav.Link href="/routers-stuck">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers stuck
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link href="/users">
                      <FontAwesomeIcon icon={faUser} className="me-2" />
                      Accounts
                    </Nav.Link>
                  </Nav.Item>  
                </>
    } else if(accesslevel === ROLE_T2_SUPPORT_AGENT) { // T2 Support Agent
      title = 'T2 Support Agent'
      navItems = <>
                  <Nav.Item>
                    <Nav.Link href="/dashboard">
                      <FontAwesomeIcon icon={faHome} className="me-2" />
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link href="/routers">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers
                    </Nav.Link>
                  </Nav.Item>
  
                  <Nav.Item>
                    <Nav.Link href="/routers-stuck">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers stuck
                    </Nav.Link>
                  </Nav.Item>                  
                </>
    } else if(accesslevel === ROLE_T1_SALES_AGENT){ // T1 Salese Agent
      title = 'T1 Sales Agent'
      navItems = <>
                  <Nav.Item>
                    <Nav.Link href="/dashboard">
                      <FontAwesomeIcon icon={faHome} className="me-2" />
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link href="/routers">
                      <FontAwesomeIcon icon={faServer} className="me-2" />
                      Routers
                    </Nav.Link>
                  </Nav.Item>                  
                </>
    }
  } else {
    title = <Skeleton baseColor='#354052' />
    navItems = <>
                <Nav.Item>
                  <Skeleton baseColor='#354052' />
                </Nav.Item>

                <Nav.Item>
                  <Skeleton baseColor='#354052' />
                </Nav.Item>

                <Nav.Item>
                  <Skeleton baseColor='#354052' />
                </Nav.Item>

                <Nav.Item>
                  <Skeleton baseColor='#354052' />
                </Nav.Item>
              </>
  }

  return (
    <nav className={`sidebar ${show? '': 'active'}`}>
      <div className="sidebar-header">        
        <h3>{ title }</h3>
      </div>
      <Nav className="flex-column pt-2">
        <p className="ms-3">
          <FontAwesomeIcon icon={faUser} className="me-2" />
          { userName }
        </p>
        { navItems }
      </Nav>
      <div className="container mt-5">
        <Button className="col-12" onClick={handleClickLogout}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2" />
          Sign out
        </Button>
      </div>
    </nav>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  logOut,
  InitialState
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
