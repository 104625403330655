import types from './types';

const defaultState = {
  users: null,
  newpassword: null, 
  error: null,  
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD_RESET: {
      return {
        ...state,
        newpassword: null,
        error: null
      }
    }
    case types.RESET_PASSWORD_START: {
      return {
        ...state,
        newpassword: null,
        error: null,
      };      
    }
    case types.SEARCH_USERS_SUCCESS:
    case types.GET_USERS_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case types.REGISTER_FAILURE:
    case types.GET_USERS_FAILURE:
    case types.SEARCH_USERS_FAILURE:
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        newpassword: action.payload?.password || null
      }
    }
    default:
      return state;
  }
};

export default reducer;
