import * as yup from 'yup';

function ipv4(message = 'Invalid IP address') {
    return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
        message,
        excludeEmptyString: true
    }).test('ip', message, value => {
        return value === undefined || value.trim() === ''
            ? true
            : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
    });
}

yup.addMethod(yup.string, 'ipv4', ipv4);

const passwordRule = () => yup.string().required().min(8, 'Must be have at least 8 symbols').max(63, 'Must be less than 64 symbols');
const nameRule = () => yup.string().required().min(2, 'Must be have at least 2 symbols').max(32, 'Must be less or equal 32 symbols');

export default yup.object().shape({
    ipAddress: yup.string().required().ipv4(),

    wifi2gNameEncrypted: nameRule(),
    wifi5gNameEncrypted: nameRule(),
    wifi2gNameClear: nameRule(),
    wifi5gNameClear: nameRule(),

    wifi2gPwEncrypted: passwordRule(),
    wifi5gPwEncrypted: passwordRule(),
    wifi2gPwClear: passwordRule(),
    wifi5gPwClear: passwordRule()
});