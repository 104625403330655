const types = {
    REGISTER_START: "REGISTER_START",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILURE: "REGISTER_FAILURE",

    UPDATE_USER_START: "RUPDATE_USER_START",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",    
    
    GET_USERS_START: "GET_USERS_START",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILURE: "GET_USERS_FAILURE",
    
    SEARCH_USERS_START: "SEARCH_USERS_START",
    SEARCH_USERS_SUCCESS: "SEARCH_USERS_SUCCESS",
    SEARCH_USERS_FAILURE: "SEARCH_USERS_FAILURE",

    RESET_PASSWORD_START: "RESET_PASSWORD_START",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    RESET_PASSWORD_RESET: "RESET_PASSWORD_RESET" 
  };
  
  export default types;
  