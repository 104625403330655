import React, { useEffect, useState } from "react";
import { BYPASS_PREFIX_MAC } from "components/tables/ConnectedClientsTable";
import { addDomainStart } from 'store/routers/routersActions';
import DomainTable from "components/tables/DomainTable";
import { useDispatch, useSelector } from 'react-redux';
import useCheckDomain from './useCheckDomain';

const decorate = value => {
    if (/^[0-9a-f]{1,}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/.test(value)) { //MAC Address
        return `${BYPASS_PREFIX_MAC}${value}`;
    }

    return value;
}

const DomainCard = () => {

    const dispatch = useDispatch();
    const { currentUser } = useSelector(state => state.auth);
    const { router } = useSelector(state => state.routers);
    const [domain, setDomain] = useState('');
    const items = router.extraparams?.domain_pass?.split(",") || [];
    const [validateError, setValidateError] = useState(false);
    const [valid, setValid] = useState(false);

    const handleChange = e => {
        setDomain(e.target.value.toLowerCase());
        setValid(false);
    }

    const { processing, checkDomain } = useCheckDomain({ onValid: () => {setValidateError(false); setValid(true)}, onInvalid: () => {setValidateError(true); setValid(false);}});

    useEffect(() => {
        if (!domain || domain == '') {
            setValidateError(false);
            setValid(false);
            return;
        }

        //IP v4
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(domain)) {
            setValid(true);
            setValidateError(false);
            return;
        }
        
        //Mac address
        if(/^[0-9a-f]{1,}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/.test(domain)){
            setValid(true);
            setValidateError(false);
            return;
        }
        
        //Domain 
        if(/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(domain)){ 
            checkDomain(domain);
            return;
        }

        setValidateError(true);

    }, [domain]);

    const handleSubmit = e => {
        e.preventDefault();

        dispatch(addDomainStart({
            token: currentUser.token,
            mac: router.detail.macaddr1,
            domain: decorate(domain)
        }));
        setDomain('');
    }

    return (
        <div className="card mt-1">
            <div className="card-header d-flex align-items-center">
                <span>Bypass domain, IP or MAC</span>
                <form className="ms-auto d-flex" onSubmit={handleSubmit}>
                    <input 
                        type="text"
                        className="form-control me-1" 
                        placeholder="Domain, IP or MAC" 
                        value={domain}
                        onChange={handleChange}
                    />
                    <button type="submit" className="btn btn-primary btn-sm" disabled={domain.length === 0 || !valid}>
                        Add
                    </button>
                </form>
                {
                    validateError &&
                    <span className="text-danger">Value is invalid</span>
                }
            </div>
            <div className="card-body">
                <DomainTable {...{items}} />
            </div>                
        </div>
    );
}
  
export default DomainCard
