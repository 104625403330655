import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RouterDetailTable from 'components/tables/RouterDetailTable'
import AppCodesTable from 'components/tables/AppCodesTable'
import ActivateAppsTable from 'components/tables/ActivateAppsTable'
import WifiSettingsCard from 'components/cards/WifiSettingsCard';
import AddAppCodesCard from 'components/cards/AddAppCodesCard';
import MeshCard from 'components/cards/MeshCard';
import DomainCard from 'components/cards/DomainCard';
import VPNCard from 'components/cards/VPNCard';
import ConnectedClientsCard from 'components/cards/ConnectedClientsCard';
import Content from 'components/Content';
import { getRouterDetailStart } from 'store/routers/routersActions';
import useCurrentUser from 'utils/useCurrentUser';
import useAccessLevel from 'utils/useAccessLevel';
import { ROLE_T2_SUPPORT_AGENT } from 'utils/roles';
import './styles.css';
import macSerialInputFilter from 'utils/macSerialInputFilter';

const RouterDetail = () => {

  const { state } = useLocation();
  const { mac } = state || {};

  const currentUser = useCurrentUser();
  const { router } = useSelector(state => state.routers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRouterDetailStart({token: currentUser.token, mac}));
  }, [currentUser, mac])


  if (!router) {
    return (
    <div className="spinner-border">
      <span className="visually-hidden">Loading...</span>
    </div>);
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            Router
          </div>
          <div className="card-body">
            <RouterDetailTable />
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-12 mt-2">
        <WifiSettingsCard {...{mac}} />
        <div className="card mt-1">
          <div className="card-header">
            Firmware
          </div>
          <div className="card-body">
            
          </div>
          <div className="card-footer">
          </div>
        </div>
        <MeshCard mac1={ mac } />
      </div>
      <div className="col-xl-6 col-md-12 mt-2">
        <AddAppCodesCard />
        <div className="card mt-1">
          <div className="card-header">
            App Codes
          </div>
          <div className="card-body">
            <AppCodesTable 
              items={ router.app_codes }
            />
          </div>                
        </div>
        <div className="card mt-1">
          <div className="card-header">
            Activated Apps
          </div>
          <div className="card-body">
            <ActivateAppsTable 
              items={ router.activated_apps }
            />
          </div>                
        </div>
        <VPNCard />
        <DomainCard />
      </div>
      <div className="col-12">
        <ConnectedClientsCard />
      </div>
    </div>
  );
};

export default ({ handleShowSideBar, ...props }) => {
  const navigate = useNavigate();
  const handleSearch = ({ searchKey: search }) => navigate(`/routers?search=${search}`);

  const accesslevel = useAccessLevel();

  useEffect(() => {
    if (+accesslevel > +ROLE_T2_SUPPORT_AGENT) {
      navigate(`/routers`);
    }
  }, [accesslevel]);

  if (+accesslevel > +ROLE_T2_SUPPORT_AGENT) {
    return null;
  }

  return (
    <Content {...{showSearch: true, searchPlaceholder: "Mac or S/N", handleShowSideBar, handleSearch, inputFilter: macSerialInputFilter}}>
      <RouterDetail {...props} />
    </Content>
  );

};
