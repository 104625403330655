import React from "react";
import { ROLE_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_SYS_ADMIN } from "utils/roles";

const ALLOWED = [ROLE_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_SYS_ADMIN];

export default ({ item, currentUser: { accesslevel }, onChange }) => {
    const isSysAdmin = +ROLE_SYS_ADMIN === +accesslevel;
    if (isSysAdmin || (ALLOWED.includes(`${accesslevel}`) && +item.accesslevel > +accesslevel)) {
        return <button className={`badge ${!item.enabled ? "bg-danger" : "bg-success"}`} style={{border: "none"}} onClick={onChange}>{item.enabled ? "No" : "Yes"}</button>;
    }
    return <span className={`badge ${!item.enabled ? "bg-danger" : "bg-success"}`} >{item.enabled ? "No" : "Yes"}</span>;
}