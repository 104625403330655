import React, { useEffect } from 'react';
import { removeStuckRoutersStart, searchStuckRoutersStart } from 'store/routers/routersActions';
import RoutersStuckTable from 'components/tables/RoutersStuckTable'
import { connect, useDispatch, useSelector } from 'react-redux';
import macSerialInputFilter from 'utils/macSerialInputFilter';
import { ROLE_T2_SUPPORT_AGENT } from 'utils/roles';
import useAccessLevel from 'utils/useAccessLevel';
import { useNavigate } from 'react-router-dom';
import Content from 'components/Content';
import './styles.css';

const RoutersStuck = ({ handleShowSideBar, ...props }) => {

  const { stuckRouters } = useSelector(state => state.routers);
  const dispatch = useDispatch();

  const accesslevel = useAccessLevel();
  const navigate = useNavigate();

  useEffect(() => {
    if (+accesslevel > +ROLE_T2_SUPPORT_AGENT) {
      navigate(`/`);
    }
  }, [accesslevel]);

  if (+accesslevel > +ROLE_T2_SUPPORT_AGENT) {
    return null;
  }

  return (
    <Content
      searchPlaceholder="Mac or S/N"
      showSearch={true}
      handleSearch={search => dispatch(searchStuckRoutersStart(search))}
      handleShowSideBar={handleShowSideBar}
      inputFilter={macSerialInputFilter}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h5 className="fw-bold">Routers stuck</h5>
              <h6 className="fw-semibold text-secondary">Update at: 2020-11-20 00:00:00</h6>
            </div>
            <div className="card-body">
              <RoutersStuckTable 
                items={stuckRouters}          
              />
            </div>
          </div>
        </div>
      </div>  
    </Content>       
  );
};

export default RoutersStuck;
