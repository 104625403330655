import React from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteDomainStart } from 'store/routers/routersActions';
import { BYPASS_PREFIX_MAC } from "./ConnectedClientsTable";
import RootTable from "./RootTable";

const decorate = item => {
  if (item.startsWith(BYPASS_PREFIX_MAC)) {
    return item.substr(BYPASS_PREFIX_MAC.length);
  }

  return item;
}

const DomainTable = ({ items }) => {

  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.auth);
  const { router } = useSelector(state => state.routers);
  const vpns = ["smtp", "netflix"]

  const handleClick = (domain) => {

    const payload = {
      token: currentUser.token,
      mac: router.detail.macaddr1,
      domain
    }

    dispatch(deleteDomainStart(payload));
  }

  return (
    <RootTable>
      <tbody>
        {
          items.filter(item => item != '' && !vpns.includes(item))
          .map((item, key) => {           
            return(
              <tr key={ key }>
                <td className="py-2 align-middle">{decorate(item)}</td>
                <td className="py-2 align-middle">
                  <button 
                    type="button" 
                    className="btn btn-danger btn-sm"
                    onClick={() => handleClick(item)}
                  >
                    Delete
                  </button>
                </td>
              </tr>  
            )  
          })
        }       
      </tbody>
    </RootTable>
  );
}

export default DomainTable;