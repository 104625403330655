import types from './types';

export const registerStart = (token, credentials) => ({type: types.REGISTER_START, payload: {token, credentials}});
export const registerSuccess = payload => ({type: types.REGISTER_SUCCESS, payload});
export const registerFailure = (error) => ({type: types.REGISTER_FAILURE, payload: error});

export const getUsersStart = (token) => ({type: types.GET_USERS_START, payload: token});
export const getUsersSuccess = payload => ({type: types.GET_USERS_SUCCESS, payload});
export const getUsersFailure = (error) => ({type: types.GET_USERS_FAILURE, payload: error});

export const searchUsersStart = ({ token, searchKey }) => ({type: types.SEARCH_USERS_START, payload: { token, searchKey }});
export const searchUsersSuccess = (data) => ({type: types.SEARCH_USERS_SUCCESS, payload: data});
export const searchUsersFailure = (error) => ({type: types.SEARCH_USERS_FAILURE, payload: error});

export const updateUserStart = ({ token, id, params }) => ({type: types.UPDATE_USER_START, payload: { token, id, params }});
export const updateUserSuccess = data => ({type: types.UPDATE_USER_SUCCESS, payload: data});
export const updateUserFailure = error => ({type: types.UPDATE_USER_FAILURE, payload: error});

export const resetPasswordStart = payload => ({type: types.RESET_PASSWORD_START, payload});
export const resetPasswordSuccess = payload => ({type: types.RESET_PASSWORD_SUCCESS, payload});
export const resetPasswordFailure = error => ({type: types.RESET_PASSWORD_FAILURE, payload: error});
export const resetPasswordReset = () => ({type: types.RESET_PASSWORD_RESET});
