import React from "react";
import { connect } from 'react-redux';
import Switch from "react-switch";
import moment from "moment";
import { updateRouterActivatedStart, rebootStart, subscriptionUpdateStart } from 'store/routers/routersActions';
import RootTable from "./RootTable";

const RouterDetailTable = (props) => {

  const { updateRouterActivatedStart, rebootStart, subscriptionUpdateStart } = props
  const { currentUser } = props.auth
  const { router, rebooting } = props.routers
  
  const handleChangeActivated = () => {

    const payload = {
      token: currentUser.token,
      email: currentUser.email,
      mac: router.detail.macaddr1,
      activated: !router.detail.activated
    }

    updateRouterActivatedStart(payload)
  }

  const handleReboot = () => {

    const payload = {
      token: currentUser.token,      
      mac: router.detail.macaddr1
    }

    rebootStart(payload)
  }

  const handleSubscriptionUpdate = () => {

    const payload = {
      token: currentUser.token,      
      mac: router.detail.macaddr1,
      valid: !router.subscription.valid
    }

    subscriptionUpdateStart(payload)
  }

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">MAC</th>
          <th scope="col">S/N</th>
          <th scope="col">Activated</th>
          <th scope="col">Subscription</th>
          <th scope="col">Reboot</th>
          <th scope="col">Status</th>
          <th scope="col">Activated by</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-2 align-middle">
            {router.detail.macaddr1}
          </td>
          <td className="py-2 align-middle">
            {router.detail.serial_number}
          </td>          
          <td className="py-2 align-middle">           
            <Switch 
              onChange={handleChangeActivated}
              checked={router.detail.activated} 
            />
          </td>                
          <td className="py-2 align-middle">
            {
              router.subscription ?
              <>
                <span className="badge bg-success">Yes</span>
                <button 
                  type="button" 
                  className="btn btn-primary btn-sm ms-1"
                  onClick={handleSubscriptionUpdate}
                >
                  { router.subscription.valid? "Disable": "Enable" }
                </button>
              </> :
              <span className="badge bg-danger">No</span>
            }                        
          </td>
          <td className="py-2 align-middle">
            <button 
              type="button" 
              className="btn btn-primary btn-sm"
              onClick={handleReboot}
            >
              { rebooting? "Rebooting...": "Reboot" }
            </button>
          </td>
          <td className="py-2 align-middle">
            {
              router.detail.status ?
              <span className="badge bg-success">Online</span> :
              <span className="badge bg-danger">Offline</span> 
            }
          </td>         
          <td className="py-2 align-middle">
            <select className="form-select">
            {
              router.app_logs.map((item, key) => {
                return(                                        
                  <option 
                    key={ key } 
                  >
                  { `${moment(item.log_time).format('lll')} - ${item.log_user}` }
                  </option>
                )
              })
            }
          </select>
          </td>
        </tr>  
      </tbody>
    </RootTable>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  routers: state.routers   
})

const mapDispatchToProps = {
  updateRouterActivatedStart,
  rebootStart,
  subscriptionUpdateStart
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterDetailTable)
