import React, { useState } from 'react';
import { connect } from 'react-redux';
import { logInStart } from '../store/auth/authActions';

const LogIn = (props) => {
  
  const { logInStart } = props
  const { error } = props.auth
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  
  function validate() {
    if(credentials.email === "" || credentials.password === "")
      return false
    
    return true
  }

  const handleChange = (e) =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault()

    if(!validate()) {
      return
    }

    logInStart(credentials);
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <main role="main" className="col-lg-12 d-flex justify-content-center" style={{ maxWidth: '400px' }}>
          <div className="mr-auto ml-auto">
            <h2 className="text-center">Routers Admin</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label fw-bold">E-mail</label>
                <input
                  name="email"
                  type="text"
                  className="form-control mb-1"
                  placeholder="" 
                  onChange={handleChange}   
                  value={credentials.email}      
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-bold">Password</label>
                <input
                  name="password"
                  type="password"
                  className="form-control mb-1"
                  placeholder=""   
                  onChange={handleChange}
                  value={credentials.password}       
                  autoComplete="off"
                />
              </div>
              {/* {
                error &&
                <p className="text-danger">Error occurred</p>
              } */}
              <button 
                type="submit" 
                className="btn btn-block btn-secondary mb-3 col-12">
                Log In
              </button>
            </form>  
          </div>
        </main>
      </div>
    </div>          
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  logInStart
}

export default connect(mapStateToProps, mapDispatchToProps)(LogIn)