import React from "react";

const RootTable = ({ children, style }) => {

  return (
    <div className="table-responsive">
      <table className="table" style={ style }>
        { children }
      </table>
    </div>
  );
}

export default RootTable;