import React from "react";
import RootTable from "./RootTable";

const RouterSearchDetailTable = (props) => {

  const { mac, activated, subscription, reboot, status, activatedAt, activatedBy } = props.item

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">MAC</th>
          <th scope="col">Activated</th>
          <th scope="col">Subscription</th>
          <th scope="col">Status</th>
          <th scope="col">Activated at</th>
          <th scope="col">Activated by</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-3 align-middle">{ mac }</td>
          <td className="py-3 align-middle">
            <span className="badge bg-success">Yes</span>
          </td>                
          <td className="py-3 align-middle">
            { '' }
          </td>
          <td className="py-3 align-middle">
            { '' }
          </td>
          <td className="py-3 align-middle">{ activatedAt }</td>
          <td className="py-3 align-middle">{ activatedBy }</td>
        </tr>  
      </tbody>
    </RootTable>
  );
}

export default RouterSearchDetailTable;