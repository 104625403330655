const routersActionTypes = {
  INITIAL_STATE: "INITIAL_STATE",

  REMOVE_ROUTERS_START: "REMOVE_ROUTERS_START",

  REMOVE_STUCK_ROUTERS_START: "REMOVE_STUCK_ROUTERS_START",
  
  GET_ROUTER_DETAIL_START: "GET_ROUTER_DETAIL_START",
  GET_ROUTER_DETAIL_SUCCESS: "GET_ROUTER_DETAIL_SUCCESS",
  GET_ROUTER_DETAIL_FAILURE: "GET_ROUTER_DETAIL_FAILURE",

  UPDATE_WIFI_SETTINGS_START: "UPDATE_WIFI_SETTINGS_START",
  UPDATE_WIFI_SETTINGS_SUCCESS: "UPDATE_WIFI_SETTINGS_SUCCESS",
  UPDATE_WIFI_SETTINGS_FAILURE: "UPDATE_WIFI_SETTINGS_FAILURE",

  ADD_APP_CODES_START: "ADD_APP_CODES_START",
  ADD_APP_CODES_SUCCESS: "ADD_APP_CODES_SUCCESS",
  ADD_APP_CODES_FAILURE: "ADD_APP_CODES_FAILURE",

  ADD_MESH_START: "ADD_MESH_START",
  ADD_MESH_SUCCESS: "ADD_MESH_SUCCESS",
  ADD_MESH_FAILURE: "ADD_MESH_FAILURE",

  DELETE_MESH_START: "DELETE_MESH_START",
  DELETE_MESH_SUCCESS: "DELETE_MESH_SUCCESS",
  DELETE_MESH_FAILURE: "DELETE_MESH_FAILURE",

  UPDATE_ROUTER_ACTIVATED_START: "UPDATE_ROUTER_ACTIVATED_START",
  UPDATE_ROUTER_ACTIVATED_SUCCESS: "UPDATE_ROUTER_ACTIVATED_SUCCESS",
  UPDATE_ROUTER_ACTIVATED_FAILURE: "UPDATE_ROUTER_ACTIVATED_FAILURE",

  SEARCH_ROUTERS_START: "SEARCH_ROUTERS_START",
  SEARCH_ROUTERS_SUCCESS: "SEARCH_ROUTERS_SUCCESS",
  SEARCH_ROUTERS_FAILURE: "SEARCH_ROUTERS_FAILURE",

  SEARCH_STUCK_ROUTERS_START: "SEARCH_STUCK_ROUTERS_START",
  SEARCH_STUCK_ROUTERS_SUCCESS: "SEARCH_STUCK_ROUTERS_SUCCESS",
  SEARCH_STUCK_ROUTERS_FAILURE: "SEARCH_STUCK_ROUTERS_FAILURE",

  ADD_DOMAIN_START: "ADD_DOMAIN_START",
  ADD_DOMAIN_SUCCESS: "ADD_DOMAIN_SUCCESS",
  ADD_DOMAIN_FAILURE: "ADD_DOMAIN_FAILURE",

  DELETE_DOMAIN_START: "DELETE_DOMAIN_START",
  DELETE_DOMAIN_SUCCESS: "DELETE_DOMAIN_SUCCESS",
  DELETE_DOMAIN_FAILURE: "DELETE_DOMAIN_FAILURE",

  UPDATE_VPN_STATUS_START: "UPDATE_VPN_STATUS_START",
  UPDATE_VPN_STATUS_SUCCESS: "UPDATE_VPN_STATUS_SUCCESS",
  UPDATE_VPN_STATUS_FAILURE: "UPDATE_VPN_STATUS_FAILURE",

  UPDATE_VPN_LOCATION_START: "UPDATE_VPN_LOCATION_START",
  UPDATE_VPN_LOCATION_SUCCESS: "UPDATE_VPN_LOCATION_SUCCESS",
  UPDATE_VPN_LOCATION_FAILURE: "UPDATE_VPN_LOCATION_FAILURE",

  GET_DEVICES_START: "GET_DEVICES_START",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_FAILURE: "GET_DEVICES_FAILURE",

  REBOOT_START: "REBOOT_START",
  REBOOT_SUCCESS: "REBOOT_SUCCESS",
  REBOOT_FAILURE: "REBOOT_FAILURE",

  SUBSCRIPTION_UPDATE_START: "SUBSCRIPTION_UPDATE_START",
  SUBSCRIPTION_UPDATE_SUCCESS: "SUBSCRIPTION_UPDATE_SUCCESS",
  SUBSCRIPTION_UPDATE_FAILURE: "SUBSCRIPTION_UPDATE_FAILURE",

  DELETE_ACTIVATED_APP_START: "DELETE_ACTIVATED_APP_START",
  DELETE_ACTIVATED_APP_SUCCESS: "DELETE_ACTIVATED_APP_SUCCESS",
  DELETE_ACTIVATED_APP_FAILURE: "DELETE_ACTIVATED_APP_FAILURE",
};

export default routersActionTypes;
