import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { removeRoutersStart, searchRoutersStart } from 'store/routers/routersActions';
import macSerialInputFilter from 'utils/macSerialInputFilter';
import RoutersTable from 'components/tables/RoutersTable'
import Content from 'components/Content';
import './styles.css';

const Routers = ({ handleShowSideBar }) => {

  const { routers } = useSelector(state => state.routers);
  const dispatch = useDispatch();
    
  useEffect(() => {
    dispatch(removeRoutersStart())
  }, []);

  return (
    <Content
      searchPlaceholder="Mac or S/N"
      showSearch={true}
      handleSearch={payload => dispatch(searchRoutersStart(payload))}
      handleShowSideBar={handleShowSideBar}
      inputFilter={macSerialInputFilter}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="fw-bold">Routers</h5>
            </div>
            <div className="card-body">
              <RoutersTable items={routers} />
            </div>
          </div>
        </div>
      </div>
    </Content>       
  );
};

export default Routers;