import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import ConnectedClientsTable, { BYPASS_PREFIX_MAC } from 'components/tables/ConnectedClientsTable'
import { addDomainStart, deleteDomainStart, getDevicesStart as getDevicesStartAction } from 'store/routers/routersActions';

const ConnectedClientsCard = () => {

  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.auth);
  const { router, devices } = useSelector(state => state.routers);
  const getDevices = router => dispatch(getDevicesStartAction({token: currentUser.token, mac: router.detail.macaddr1}));

  useEffect(() => {
    getDevices(router);
    const interval = setInterval(() => getDevices(router), 60000);
    
    return () => clearInterval(interval);
  }, [router]);

  const onDisableBypass = mac => dispatch(deleteDomainStart({ token: currentUser.token, mac: router.detail.macaddr1, domain: `${BYPASS_PREFIX_MAC}${mac}`}));
  const onEnableBypass = mac => dispatch(addDomainStart({ token: currentUser.token, mac: router.detail.macaddr1, domain: `${BYPASS_PREFIX_MAC}${mac}`}));

  return (
    <div className="card mt-1">
      <div className="card-header">
        Connected Clients
      </div>
      <div className="card-body">
        <ConnectedClientsTable {...{router, devices, onEnableBypass, onDisableBypass}} />
      </div>
    </div>        
  );
}

export default ConnectedClientsCard;
