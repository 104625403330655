import React from "react";
import LocationStatisticTable from "components/tables/LocationStatisticTable";

export default ({ data }) => {
    const { OverallVpnStats: stats } = data || {};

    const { AllServers: items } = stats;

    return (
        <div className="card mt-3">
            <div className="card-header">
                <h5 className="fw-bold">Location Statistic</h5>
                <h6 className="fw-semibold text-secondary">Update at: {stats.UpdateTime}</h6>
            </div>
            <div className="card-body">
                <LocationStatisticTable {...{items}} />
            </div>
        </div>
    );
}