import React from 'react';
import { connect } from 'react-redux';
import Content from 'components/Content';
import RouterSearchDetailTable from 'components/tables/RouterSearchDetailTable'
import { searchRoutersStart } from 'store/routers/routersActions';
import macSerialInputFilter from 'utils/macSerialInputFilter';
import './styles.css';

const RouterSearchDetail = (props) => {

  const { handleShowSideBar, searchRoutersStart } = props

  return (
    <Content
      searchPlaceholder="Mac or S/N"
      showSearch={true}
      handleSearch={searchRoutersStart }
      handleShowSideBar={handleShowSideBar}
      inputFilter={macSerialInputFilter}
    >
      <div className="card shadow-sm">
        <div className="card-header">
          Router
        </div>
        <div className="card-body">
          <RouterSearchDetailTable 
            item={{
              mac: "A1:A1:A1:A1:A1:A1", 
              activated: "", 
              subscription: "", 
              reboot: "", 
              status: "", 
              activatedAt: "4:08 PM", 
              activatedBy: "Unknown"
            }}
          />
        </div>
      </div>  
      <div className="card shadow-sm mt-2">
        <div className="card-header">
          Wifi Settings
        </div>
        <div className="card-body">
          <h6 className="fw-bold">VPN Location</h6>
          <h6>United Kingdom - London</h6>
        </div>
      </div>  
    </Content>            
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  searchRoutersStart
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterSearchDetail)
