import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  getRouterDetailSuccess,
  getRouterDetailFailure,
  updateWifiSettingsSuccess,
  updateWifiSettingsFailure,
  addAppCodeSuccess,
  addAppCodeFailure,
  updateRouterActivatedSuccess,
  updateRouterActivatedFailure,
  searchRoutersSuccess,
  searchRoutersFailure,
  searchStuckRoutersSuccess,
  searchStuckRoutersFailure,
  addMeshSuccess,
  addMeshFailure,
  addDomainSuccess,
  addDomainFailure,
  deleteDomainSuccess,
  deleteDomainFailure,
  updateVpnStatusSuccess,
  updateVpnStatusFailure,
  deleteMeshSuccess,
  deleteMeshFailure,
  updateVpnLocationSuccess,
  updateVpnLocationFailure,
  getDevicesSuccess,
  getDevicesFailure,
  rebootSuccess,
  rebootFailure,
  subscriptionUpdateSuccess,
  subscriptionUpdateFailure,
  deleteActivatedAppSuccess,
  deleteActivatedAppFailure,
} from './routersActions';
import types from './routersActionTypes';

const callAPI = async (url, method, payload) => {
  
  const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_API2_HOST_URL : "api2"
  const response = await fetch(`${rootUrl}/routers/${url}`, {
    method: method,
    headers: {
      "x-access-token": payload.token,
      "Content-Type": 'application/json'
    },
    body: JSON.stringify(payload)
  })

  const data = await response.json()   
  return data
}

const getRouterDetailAPI = async (payload) => {
  const data = await callAPI('show', 'POST', payload)
  return data;
};

const updateWifiSettingsAPI = async (payload) => {
  const data = await callAPI('wifisettings', 'POST', payload)
  return data;
};

const addAppCodeAPI = async (payload) => {  
  const data = await callAPI('appcodes', 'POST', payload)
  return data;
};

const updateRouterActivatedAPI = async (payload) => {
  const data = await callAPI('activated', 'POST', payload)
  return data;
};

const searchRoutersAPI = async (payload) => {
  const data = await callAPI('search', 'POST', payload)
  return data;
};

const searchStuckRoutersAPI = async (payload) => {
  const data = await callAPI('stuck/search', 'POST', payload)
  return data;
};

const addMeshAPI = async (payload) => {  
  const data = await callAPI('mesh', 'POST', payload)
  return data;
};

const deleteMeshAPI = async (payload) => {
  const data = await callAPI('mesh/delete', 'DELETE', payload)
  return data;
};

const addDomainAPI = async (payload) => {
  const data = await callAPI('domain', 'POST', payload)
  return data;
};

const deleteDomainAPI = async (payload) => {      
  const data = await callAPI('domain/delete', 'DELETE', payload)
  return data;
};

const updateVpnStatusAPI = async (payload) => {  
  const data = await callAPI('vpn/status', 'POST', payload)
  return data;
};

const updateVpnLocationAPI = async (payload) => { 
  const data = await callAPI('vpn_location', 'PUT', payload)
  return data;
};

const getDevicesAPI = async (payload) => {
  const data = await callAPI('devices', 'POST', payload)
  console.log("getDevicesAPI")
  console.log("payload =", payload)
  console.log("data =", data)
  return data;
};

const rebootAPI = async (payload) => {
  const data = await callAPI('reboot', 'POST', payload)
  console.log("data =", data)
  return data;
};

const subscriptionUpdateAPI = async (payload) => {
  const data = await callAPI('subscription', 'POST', payload)
  console.log("data =", data)
  return data;
};

const deleteActivatedAppAPI = async (payload) => {  
  const data = await callAPI('activatedApp/delete', 'DELETE', payload)
  return data;
};

export function* getRouterDetail({ payload }) {
  try {
    const data = yield getRouterDetailAPI(payload);
    yield put(getRouterDetailSuccess(data));
  } catch (error) {
    yield put(getRouterDetailFailure(error));
  }
}

export function* updateWifiSettings({ payload }) {
  try {
    const data = yield updateWifiSettingsAPI(payload);
    yield put(updateWifiSettingsSuccess(data));
  } catch (error) {
    yield put(updateWifiSettingsFailure(error));
  }
}

export function* addAppCode({ payload }) {
  try {
    const data = yield addAppCodeAPI(payload);
    yield put(addAppCodeSuccess(data));
  } catch (error) {
    yield put(addAppCodeFailure(error));
  }
}

export function* updateRouterActivated({ payload }) {
  try {
    const data = yield updateRouterActivatedAPI(payload);
    yield put(updateRouterActivatedSuccess(data));
  } catch (error) {
    yield put(updateRouterActivatedFailure(error));
  }
}

export function* searchRouters({ payload }) {
  try {
    const data = yield searchRoutersAPI(payload);
    yield put(searchRoutersSuccess(data));
  } catch (error) {
    yield put(searchRoutersFailure(error));
  }
}

export function* searchStuckRouters({ payload }) {
  try {
    const data = yield searchStuckRoutersAPI(payload);
    yield put(searchStuckRoutersSuccess(data));
  } catch (error) {
    yield put(searchStuckRoutersFailure(error));
  }
}

export function* addMesh({ payload }) {
  try {
    const data = yield addMeshAPI(payload);
    yield put(addMeshSuccess(data));
  } catch (error) {
    yield put(addMeshFailure(error));
  }
}

export function* deleteMesh({ payload }) {
  try {
    const data = yield deleteMeshAPI(payload);
    yield put(deleteMeshSuccess(data));
  } catch (error) {
    yield put(deleteMeshFailure(error));
  }
}

export function* addDomain({ payload }) {
  try {
    const data = yield addDomainAPI(payload);
    yield put(addDomainSuccess(data));
  } catch (error) {
    yield put(addDomainFailure(error));
  }
}

export function* deleteDomain({ payload }) {
  try {
    const data = yield deleteDomainAPI(payload);
    yield put(deleteDomainSuccess(data));
  } catch (error) {
    yield put(deleteDomainFailure(error));
  }
}

export function* updateVpnStatus({ payload }) {
  try {
    const data = yield updateVpnStatusAPI(payload);
    yield put(updateVpnStatusSuccess(data));
  } catch (error) {
    yield put(updateVpnStatusFailure(error));
  }
}

export function* updateVpnLocation({ payload }) {
  try {
    const data = yield updateVpnLocationAPI(payload);
    yield put(updateVpnLocationSuccess(data));
  } catch (error) {
    yield put(updateVpnLocationFailure(error));
  }
}

export function* getDevices({ payload }) {
  try {
    const data = yield getDevicesAPI(payload);
    yield put(getDevicesSuccess(data));
  } catch (error) {
    yield put(getDevicesFailure(error));
  }
}

export function* reboot({ payload }) {
  try {
    const data = yield rebootAPI(payload);
    yield put(rebootSuccess(data));
  } catch (error) {
    yield put(rebootFailure(error));
  }
}

export function* subscriptionUpdate({ payload }) {
  try {
    const data = yield subscriptionUpdateAPI(payload);
    yield put(subscriptionUpdateSuccess(data));
  } catch (error) {
    yield put(subscriptionUpdateFailure(error));
  }
}

export function* deleteActivatedApp({ payload }) {
  try {
    const data = yield deleteActivatedAppAPI(payload);
    yield put(deleteActivatedAppSuccess(data));
  } catch (error) {
    yield put(deleteActivatedAppFailure(error));
  }
}

export function* onGetRouterDetailStart() {
  yield takeLatest(types.GET_ROUTER_DETAIL_START, getRouterDetail);
}

export function* onUpdateWifiSettingsStart() {
  yield takeLatest(types.UPDATE_WIFI_SETTINGS_START, updateWifiSettings);
}

export function* onAddAppCodeStart() {
  yield takeLatest(types.ADD_APP_CODES_START, addAppCode);
}

export function* onUpdateRouterActivatedStart() {
  yield takeLatest(types.UPDATE_ROUTER_ACTIVATED_START, updateRouterActivated);
}

export function* onSearchRoutersStart() {
  yield takeLatest(types.SEARCH_ROUTERS_START, searchRouters);
}

export function* onSearchStuckRoutersStart() {
  yield takeLatest(types.SEARCH_STUCK_ROUTERS_START, searchStuckRouters);
}

export function* onAddMeshStart() {
  yield takeLatest(types.ADD_MESH_START, addMesh);
}

export function* onDeleteMeshStart() {
  yield takeLatest(types.DELETE_MESH_START, deleteMesh);
}

export function* onAddDomainStart() {
  yield takeLatest(types.ADD_DOMAIN_START, addDomain);
}

export function* onDeleteDomainStart() {
  yield takeLatest(types.DELETE_DOMAIN_START, deleteDomain);
}

export function* onUpdateVpnStatusStart() {
  yield takeLatest(types.UPDATE_VPN_STATUS_START, updateVpnStatus);
}

export function* oUpdateVpnLocationStart() {
  yield takeLatest(types.UPDATE_VPN_LOCATION_START, updateVpnLocation);
}

export function* onGetDevicesStart() {
  yield takeLatest(types.GET_DEVICES_START, getDevices);
}

export function* onRebootStart() {
  yield takeLatest(types.REBOOT_START, reboot);
}

export function* onSubscriptionUpdateStart() {
  yield takeLatest(types.SUBSCRIPTION_UPDATE_START, subscriptionUpdate);
}

export function* onDeleteActivatedAppStart() {
  yield takeLatest(types.DELETE_ACTIVATED_APP_START, deleteActivatedApp);
}

export function* routersSagas() {
  yield all([
    call(onGetRouterDetailStart),
    call(onUpdateWifiSettingsStart),
    call(onAddAppCodeStart),
    call(onUpdateRouterActivatedStart),
    call(onSearchRoutersStart),
    call(onSearchStuckRoutersStart),
    call(onAddMeshStart),
    call(onAddDomainStart),
    call(onUpdateVpnStatusStart),
    call(onDeleteDomainStart),
    call(onDeleteMeshStart),
    call(oUpdateVpnLocationStart),
    call(onGetDevicesStart),
    call(onRebootStart),
    call(onSubscriptionUpdateStart),
    call(onDeleteActivatedAppStart)
  ]);
}
