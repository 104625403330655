import React from "react";
import RoleSelector from "./components/RoleSelector";
import { labels, ROLE_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_SYS_ADMIN } from 'utils/roles';

const ALLOWED = [ROLE_ADMIN, ROLE_SYS_ADMIN];

export default ({ user, accesslevel, onChange }) => {
    const isSysAdmin = +ROLE_SYS_ADMIN === +accesslevel;
    if (isSysAdmin || (ALLOWED.includes(`${accesslevel}`) && +user.accesslevel > +accesslevel)) {
        return <RoleSelector {...{onChange, value: user.accesslevel}} />;
    }

    return labels[user.accesslevel] || 'Unknown';
}