export const ROLE_SYS_ADMIN = '1';
export const ROLE_ADMIN = '2';
export const ROLE_SUPPORT_ADMIN = '3';
export const ROLE_T2_SUPPORT_AGENT = '4';
export const ROLE_T1_SALES_AGENT = '5';

export const roles = [
    ROLE_SYS_ADMIN,
    ROLE_ADMIN,
    ROLE_SUPPORT_ADMIN,
    ROLE_T2_SUPPORT_AGENT,
    ROLE_T1_SALES_AGENT
];

export const labels = {
    [ROLE_SYS_ADMIN]: 'System Administrator',
    [ROLE_ADMIN]: 'Administrator',
    [ROLE_SUPPORT_ADMIN]: 'Support Administrator',
    [ROLE_T2_SUPPORT_AGENT]: 'T2 Support Agent',
    [ROLE_T1_SALES_AGENT]: 'T1 Sales Agent'
}

export default roles;