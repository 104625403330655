import { all, call } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';
import { routersSagas } from './routers/routersSagas';
import { dashboardSagas } from './dashboard/dashboardSagas';
import { sagas as usersSagas } from 'screens/Accounts/store/sagas';

export default function* rootSaga() {
  yield all([
    call(authSagas), 
    call(routersSagas),
    call(dashboardSagas),
    call(usersSagas),
  ]);
}
