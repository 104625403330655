import AgentStatisticTable from "./AgentStatisticTable";

export default () => {
//<div className="col-lg-6 col-md-12 mt-3">
    return  (
        <div className="card">
            <div className="card-header">
                <h5 className="fw-bold">Agent Statistic</h5>
                <h6 className="fw-semibold text-secondary">Update at: 2020-11-20 00:00:00</h6>
            </div>
            <div className="card-body">
                <AgentStatisticTable
                    items={[{
                        agent: "1000",
                        today: "800",
                        thisWeek: "200",
                        thisMonth: "600",
                        thisYear: "400",
                        total: "800"
                    }]}
                />
            </div>
        </div>
    );
}
