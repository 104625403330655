import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetPasswordReset, resetPasswordStart } from "screens/Accounts/store/actions";
import { ROLE_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_SYS_ADMIN } from "utils/roles";


const Copy = ({ password, onHide }) => {

  return <>
    {password}
    <button type="button" className="btn btn-secondary btn-sm" onClick={() => onHide()}>Hide</button>
  </>
}


export default ({ currentUser: { token, email: login }, currentUser: { accesslevel }, item, password }) => {

    const dispatch = useDispatch();
    const [passwordAction, setPasswordAction] = useState(false);
    const onResetPassword = () => {setPasswordAction(true); dispatch(resetPasswordStart({token, id: item.login}))};
    const [newpassword, setNewPassword] = useState(null);

    useEffect(() => {
      if (!password) {
        return;
      }
      setNewPassword(password);
    }, [password]);

    useEffect(() => {
      if (!passwordAction) {
        setNewPassword(null);
      }
    }, [passwordAction]);

    const isSysAdmin = +ROLE_SYS_ADMIN === +accesslevel;

    if (!(isSysAdmin || +ROLE_SYS_ADMIN === +accesslevel || [ROLE_SYS_ADMIN, ROLE_ADMIN, ROLE_SUPPORT_ADMIN].includes(`${accesslevel}`) || login === item.login)) {
      return null;
    }

    if (!isSysAdmin && login !== item.login && +item.accesslevel < +accesslevel) {
      return null;
    }

    if (passwordAction) {
      if (!newpassword) {
        return '...';
      }
      return <Copy {...{password: newpassword, onHide: () => {setPasswordAction(false); dispatch(resetPasswordReset())}}} />;
    }

    return <button type="button" className="btn btn-secondary btn-sm" onClick={() => onResetPassword()}>Reset</button>;
}