import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";

const defaultFilter = input => input;

const TopBar = (props) => {

  const { handleShowSideBar, showSearch, handleSearch, searchPlaceholder, inputFilter = defaultFilter } = props
  const { currentUser: { token } } = props.auth
  // const [searchKey, setSearchKey] = useState('')
  const [searchParams, setSearchParams] = useSearchParams({});
  const search = searchParams.get('search')
  const [searchKey, setSearchKey] = useState(inputFilter(search));

  const handleChange = e => setSearchKey(inputFilter(e.target.value));

  const handleSubmit = (e) => {
    e.preventDefault()

    if(searchKey === "") {
      return;
    }

    setSearchParams({search: searchKey});
  }

  useEffect(() => {
    if (!search) {
      return;
    }
    handleSearch({token, searchKey: search});
  }, [search]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <button type="button" className="btn btn-secondary" onClick={() => handleShowSideBar()}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        {showSearch && 
          <form className="d-flex" onSubmit={ handleSubmit }>
            <input 
              type="text" 
              className="form-control" 
              placeholder={searchPlaceholder}
              value={searchKey}
              onChange={handleChange}
            />        
            <button className="btn btn-secondary" type="submit">
              Search
            </button>
          </form>
        }        
      </div>      
    </nav>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
