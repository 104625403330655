import * as React from "react";
import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from "react-router-dom";
import { decodeToken, isExpired } from "react-jwt";
import LogIn from './screens/LogIn';
import Dashboard from './screens/Dashboard';
import Routers from './screens/Routers';
import RoutersStuck from './screens/RoutersStuck';
import RouterDetail from './screens/RouterDetail';
import RouterSearchDetail from './screens/RouterSearchDetail';
import Accounts from './screens/Accounts';
import Sidebar from './components/Sidebar';

import "./App.css";
import useCurrentUser from "utils/useCurrentUser";

const App = props => {

  const currentUser = useCurrentUser();
  const [showSideBar, setShowSideBar] = React.useState(true)
  const navigate = useNavigate()

  React.useEffect(() => {
    if(!currentUser) {
      navigate("/")
    }
  }, [isExpired, currentUser, navigate]);

  React.useEffect(() => {
    if (!currentUser || !currentUser.token) {
      return
    }
    const { exp } = decodeToken(currentUser.token);
    const will_exp_at = (+exp)*1000 - +Date.now();
    if (will_exp_at < 0) {
      return;
    }
    const watcher = setTimeout(() => navigate(0), will_exp_at);
    return () => clearTimeout(watcher);
  }, [currentUser])

  const handleShowSideBar = () => {
    setShowSideBar(!showSideBar)
  }

  if (!currentUser || isExpired(currentUser?.token)) {
    return <div className="App">
        <LogIn />
    </div>
  }

  return (
    <div className="App">      
      {
        currentUser ?
        <>
          <Sidebar show={ showSideBar }/>
          <Routes>
            <Route exact path="/" element={<Dashboard handleShowSideBar={ handleShowSideBar } />} />
            <Route exact path="/dashboard" element={<Dashboard handleShowSideBar={ handleShowSideBar }/>} />
            <Route exact path="/routers" element={<Routers handleShowSideBar={ handleShowSideBar }/>} />
            <Route exact path="/routers-stuck" element={<RoutersStuck handleShowSideBar={ handleShowSideBar }/>} />
            <Route exact path="/router-detail" element={<RouterDetail handleShowSideBar={ handleShowSideBar }/>} />
            <Route exact path="/router-search-detail" element={<RouterSearchDetail handleShowSideBar={ handleShowSideBar }/>} />
            <Route exact path="/users" element={<Accounts handleShowSideBar={ handleShowSideBar }/>} />
          </Routes>
        </> :
        <Routes>
          <Route exact path="/login" element={<LogIn />} />
          <Route exact path="/" element={<LogIn />} />
        </Routes>
      }           
    </div>
  );
}

export default App;
