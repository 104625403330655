import React from "react";
import { connect } from 'react-redux';
import { updateVpnStatusStart } from 'store/routers/routersActions';
import RootTable from "./RootTable";

const VPNPassthroughsTable = (props) => {

  const { updateVpnStatusStart, items } = props
  const { currentUser } = props.auth
  const { router } = props.routers
  const domains = router.extraparams?.domain_pass?.split(",") || [];

  const handleClick = (domain, status) => {

    const payload = {
      token: currentUser.token,
      mac: router.detail.macaddr1,
      domain,
      status
    }

    updateVpnStatusStart(payload)
  }

  return (
    <RootTable>
      <tbody>
        {
          domains && items.map((item, key) => {            
            return (
              <tr key={ key }>
                <td className="py-2 align-middle">{ item }</td>
                <td className="py-2 align-middle">
                  {
                    domains.includes(item) ?
                    <span className="badge bg-success">Yes</span>:
                    <span className="badge bg-danger">No</span>
                  }                  
                </td>
                <td className="py-2 align-middle">
                  {
                    domains.includes(item) ?
                    <button 
                      type="button" 
                      className="btn btn-primary btn-sm"
                      onClick={() => handleClick(item, false)}
                    >
                        Disable
                      </button>:
                    <button 
                      type="button" 
                      className="btn btn-primary btn-sm"
                      onClick={() => handleClick(item, true)}
                    >
                        Enable
                    </button>
                  }                   
                </td>
              </tr>  
            )
          })
        }              
      </tbody>
    </RootTable>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  routers: state.routers   
})

const mapDispatchToProps = {
  updateVpnStatusStart
}

export default connect(mapStateToProps, mapDispatchToProps)(VPNPassthroughsTable)