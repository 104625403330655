import React from "react";
import { connect } from 'react-redux';
import VPNPassthroughsTable from 'components/tables/VPNPassthroughsTable'

const DomainCard = (props) => {

    return (
        <div className="card mt-1">
              <div className="card-header">
                VPN Passthrough
              </div>
              <div className="card-body">
                <VPNPassthroughsTable
                  items={["smtp", "netflix"]}
                />
              </div>                
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    routers: state.routers   
})
  
const mapDispatchToProps = {
    
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DomainCard)
