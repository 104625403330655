
const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_API2_HOST_URL : "api2";

export const createUserAPI = async (token, payload) => {
    const response = await fetch(`${rootUrl}/users/create`, {
        method: 'POST',
        headers: {
          "x-access-token": token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    
    const data = await response.json()     
    return data;
}

export const updateUserAPI = async (token, payload) => {
  const response = await fetch(`${rootUrl}/users/update`, {
      method: 'POST',
      headers: {
        "x-access-token": token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
  })
  
  const data = await response.json()     
  return data;
}

export const getUsersAPI = async (token) => {

    const response = await fetch(`${rootUrl}/users`, {
      method: 'GET',
      headers: {
        "x-access-token": token      
      }
    })
  
    const data = await response.json()   
    return data;
};
  
export const searchUsersAPI = async (token, searchKey) => {

    const payload = {
        searchKey
    }

    const response = await fetch(`${rootUrl}/users/search`, {
        method: 'POST',
        headers: {
        "x-access-token": token,
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    const data = await response.json()   
    return data;
};

export const resetPasswordAPI = async (token, id) => {
  const response = await fetch(`${rootUrl}/users/passwordreset`, {
    method: 'POST',
    headers: {
      "x-access-token": token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id })
  });
  const data = await response.json();
  return data;
}
  