import types from './dashboardActionTypes';

const defaultState = {
  data: null,
  loading: false,
  error: null,
};

const dashboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_START: {
      return {
        ...defaultState,
        loading: true
      }
    }
    case types.GET_DASHBOARD_SUCCESS:
      return {
        ...defaultState,
        data: action.payload
      };
    case types.GET_DASHBOARD_FAILURE:
      return {
        ...defaultState,
        error: action.payload,
      };
    case types.INITIAL_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default dashboardReducer;
