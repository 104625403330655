import React from "react";
import TopBar from "./TopBar";

const Content = (props) => {

    const { children, searchPlaceholder, showSearch, handleSearch, handleShowSideBar, inputFilter } = props

    return (
        <div className="container-fluid content">
            <TopBar {...{showSearch, searchPlaceholder, handleSearch, handleShowSideBar, inputFilter}} />
            {children}
        </div>  
    );
}

export default Content;