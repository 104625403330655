import React, { useEffect, useState } from "react";
import { getUsersStart, resetPasswordStart, updateUserStart } from "screens/Accounts/store/actions";
import { useDispatch, useSelector } from "react-redux";
import RootTable from "components/tables/RootTable";
import Role from "./components/Role";
import PasswordReset from "./components/PasswordReset";
import './styles.css';
import Enable from "./components/Enable";
import useCurrentUser from "utils/useCurrentUser";
import useAccessLevel from "utils/useAccessLevel";
import { ROLE_ADMIN } from "utils/roles";

const AccountsTable = ({ showDisabled }) => {

  const currentUser = useCurrentUser();
  const accesslevel = useAccessLevel();

  const enableManage = +accesslevel <= ROLE_ADMIN;
  const { users: items, newpassword: password } = useSelector(state => state.users);

  const dispatch = useDispatch();

  const enDisableUser = user => {
    const { login: id, enabled } = user;
    enableManage && dispatch(updateUserStart({token: currentUser.token, id, params: {enabled: !enabled}}));
  }

  const setRoleUser = (user, role) => {
    const { login: id } = user;
    enableManage && dispatch(updateUserStart({token: currentUser.token, id, params: {accesslevel: role}}));
  }


  useEffect(() => {
    dispatch(getUsersStart({token: currentUser.token}));
  }, [getUsersStart, currentUser])

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Role</th>
          <th scope="col">Disabled</th>
          {enableManage && <th scope="col">Password</th>}
        </tr>
      </thead>
      <tbody>
        {
          items && items.filter(item => showDisabled || !!item.enabled).map((item, key) => {

            return (
              <tr key={ key }>
                <td className="py-2 align-middle">{item.name || '-'}</td>
                <td className="py-2 align-middle">{item.login}</td>
                <td className="py-2 align-middle">
                  <Role {...{user: item, accesslevel, onChange: e => setRoleUser(item, e.target.value)}} />
                </td>
                <td className="py-2 align-middle">
                  <Enable {...{item, currentUser, onChange: () => enDisableUser(item)}} />
                </td>
                {enableManage && <td className="py-2 align-middle password-reset">
                  <PasswordReset {...{currentUser, item, password}} />
                </td>}
              </tr>  
            )
          })
        }              
      </tbody>
    </RootTable>
  );
}

export default AccountsTable;