import React from "react";
import { useNavigate } from "react-router-dom"
import { ROLE_T2_SUPPORT_AGENT } from "utils/roles";
import useAccessLevel from "utils/useAccessLevel";
import RootTable from "./RootTable";

const RoutersTable = ({ items }) => {

  const accesslevel = useAccessLevel();
  const navigate = useNavigate();
  const handleClickShow = mac => navigate('/router-detail', { state: { mac } });

  const viewEnable = +accesslevel <= +ROLE_T2_SUPPORT_AGENT;

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">Model</th>
          <th scope="col">S/N</th>
          <th scope="col">MAC</th>
          <th scope="col">Firmware</th>
          <th scope="col">Status</th>
          {viewEnable && <th scope="col"></th>}
        </tr>
      </thead>
      <tbody>
        {
          items && items.map((item, key)=> {
            return (
              <tr key={ key }>
                <th className="py-2 align-middle">{item.model}</th>
                <th className="py-2 align-middle">{item.serial_number}</th>
                <td className="py-2 align-middle">{item.macaddr1}</td>
                <td className="py-2 align-middle">{'10'}</td>
                <td className="py-2 align-middle">
                  { 
                    item.status ? 
                    <span className="badge bg-success">Online</span>: 
                    <span className="badge bg-danger">Offline</span>
                  }
                </td>
                {viewEnable && <td className="py-2 align-middle">
                  <button 
                    type="button" 
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleClickShow(item.macaddr1)}
                  >
                    Show
                  </button>
                </td>}
              </tr>  
            )
          })
        }              
      </tbody>     
    </RootTable>
  );
}

export default RoutersTable;