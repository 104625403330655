import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateWifiSettingsStart, updateRouterActivatedStart, updateVpnLocationStart } from 'store/routers/routersActions';
import schema from "./schema";
import * as yup from 'yup';

const nameInputFilter = input => input?.replace(/[^a-z0-9-_ ]/gi, '').substring(0, 32);
const passwordInputFilter = input => input?.replace(/[^\x20-\x7E]/gi, '').substring(0, 63);
// const ipAddressInputFilter = input => 

const WifiSettingsCard = ({ mac }) => {

    const dispatch = useDispatch();

    const { currentUser } = useSelector(state => state.auth);
    const { token } = currentUser;
    const { router, wifiSettingsUpdating, routerActivatedUpdating } = useSelector(state => state.routers);

    const [params, setParams] = useState({
        ipAddress: router.extraparams?.lan_ip, 
        wifi2gNameEncrypted: router.extraparams ? router.extraparams.wifi2g_name_encrypted: '',
        wifi2gPwEncrypted: router.extraparams ? router.extraparams.wifi2g_pw_encrypted: '',
        wifi5gNameEncrypted: router.extraparams ? router.extraparams.wifi5g_name_encrypted: '',
        wifi5gPwEncrypted: router.extraparams ? router.extraparams.wifi5g_pw_encrypted: '',
        wifi2gNameClear: router.extraparams ? router.extraparams.wifi2g_name_clear: '',
        wifi2gPwClear: router.extraparams ? router.extraparams.wifi2g_pw_clear: '',
        wifi5gNameClear: router.extraparams ? router.extraparams.wifi5g_name_clear: '',
        wifi5gPwClear: router.extraparams ? router.extraparams.wifi5g_pw_clear: '',
        authtype2g: router?.extraparams?.wifi2_auth_type,
        authtype5g: router?.extraparams?.wifi5_auth_type,

    });

    useEffect(() => {
        setParams({ 
            ipAddress: router.extraparams?.lan_ip, 
            wifi2gNameEncrypted: router.extraparams ? router.extraparams.wifi2g_name_encrypted: '',
            wifi2gPwEncrypted: router.extraparams ? router.extraparams.wifi2g_pw_encrypted: '',
            wifi5gNameEncrypted: router.extraparams ? router.extraparams.wifi5g_name_encrypted: '',
            wifi5gPwEncrypted: router.extraparams ? router.extraparams.wifi5g_pw_encrypted: '',
            wifi2gNameClear: router.extraparams ? router.extraparams.wifi2g_name_clear: '',
            wifi2gPwClear: router.extraparams ? router.extraparams.wifi2g_pw_clear: '',
            wifi5gNameClear: router.extraparams ? router.extraparams.wifi5g_name_clear: '',
            wifi5gPwClear: router.extraparams ? router.extraparams.wifi5g_pw_clear: '',
            authtype2g: router?.extraparams?.wifi2_auth_type,
            authtype5g: router?.extraparams?.wifi5_auth_type
        })
    }, [router]);

    const [error, setError] = useState({});

    useEffect(() => {
        // console.log('Params changed', params);
        try {
            schema.validateSync(params, {abortEarly: false});
            setError({});
            return;
        } catch (e) {
            if (e instanceof yup.ValidationError) {
                const errors = {};
                e.inner?.forEach(e => errors[e.path] = e.errors?.[0]);
                setError(errors);
            }
        }

    }, [params]);

    const handleChange = (filter = i => i)  => e => setParams({...params, [e.target.name]: filter(e.target.value)});

    const handleClickUpdate = () => dispatch(updateWifiSettingsStart({token, mac, params}));

    const handleClickRouterActivated = () => {
        const payload = {
            token,
            email: currentUser.email,
            mac,
            activated: !router.detail.activated
        }
        dispatch(updateRouterActivatedStart(payload));
    }

    const handleChangeLocation = (e) => {
        const payload = {
            token, 
            mac, 
            server_ip: e.target.value
        }
        dispatch(updateVpnLocationStart(payload));
    }

    const haveErrors = !!Object.keys(error).length;

    return (
        <div className="card">
            <div className="card-header">
                Wifi Settings
            </div>
            <div className="card-body">
                <div className="row">
                <div className="w-50">
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bold ${!!error?.wifi2gNameEncrypted ? 'label-error' : ''}`}>
                            Encrypted 2G SSID
                        </label>
                        <input 
                            name="wifi2gNameEncrypted"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi2gNameEncrypted}
                            onChange={handleChange(nameInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${error?.wifi5gNameEncrypted ? 'label-error' : ''}`}>
                            Encrypted 5G SSID
                        </label>
                        <input 
                            name="wifi5gNameEncrypted"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi5gNameEncrypted}
                            onChange={handleChange(nameInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${error?.wifi2gNameClear ? 'label-error' : ''}`}>
                            Unencrypted 2G SSID
                        </label>
                        <input 
                            name="wifi2gNameClear"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi2gNameClear}
                            onChange={handleChange(nameInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.wifi5gNameClear ? 'label-error' : ''}`}>
                            Unencrypted 5G SSID
                        </label>
                        <input 
                            name="wifi5gNameClear"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi5gNameClear}
                            onChange={handleChange(nameInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.ipAddress ? 'label-error' : ''}`}>
                            IP Address
                        </label>
                        <input 
                            name="ipAddress"
                            className="form-control" 
                            placeholder="" 
                            value={params.ipAddress}
                            type="text" minlength="7" maxlength="15" size="15"
                            onChange={handleChange()}                            
                        />
                    </div>
                </div>
                <div className="w-50">
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.wifi2gPwEncrypted ? 'label-error' : ''}`}>
                            Encrypted 2G Password
                        </label>
                        <input 
                            name="wifi2gPwEncrypted"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi2gPwEncrypted}
                            onChange={handleChange(passwordInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.wifi5gPwEncrypted ? 'label-error' : ''}`}>
                            Encrypted 5G Password
                        </label>
                        <input 
                            name="wifi5gPwEncrypted"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi5gPwEncrypted}
                            onChange={handleChange(passwordInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.wifi2gPwClear ? 'label-error' : ''}`}>
                            Unencrypted 2G Password
                        </label>
                        <input 
                            name="wifi2gPwClear"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi2gPwClear}
                            onChange={handleChange(passwordInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.wifi5gPwClear ? 'label-error' : ''}`}>
                            Unencrypted 5G Password
                        </label>
                        <input 
                            name="wifi5gPwClear"
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={params.wifi5gPwClear}
                            onChange={handleChange(passwordInputFilter)}
                        />
                    </div>
                    <div className="mt-1">
                        <label className="form-label fs-7 fw-bolder">VPN Location</label>
                        <select 
                            className="form-select"
                            onChange={handleChangeLocation}
                            value={router.selected_location.server_ip}
                        >
                            {
                                router.countries.map((item, key) => {
                                    return(                                        
                                        <option key={key} value={item.server_ip}>
                                            {`${item.long_name} - ${item.description}`}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="w-50">
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.enctype ? 'label-error' : ''}`}>
                            WIFI encryption type 2G
                        </label>                        
                        <select name="authtype2g" className="form-select" onChange={handleChange()} value={params?.authtype2g} >
                            {
                                router.wifiauthtypes?.map((item, key) => {
                                    return(                                        
                                        <option key={key} value={item.authstring}>{item.name}</option>
                                    )
                                })
                            }
                        </select>                        
                    </div>
                </div>
                <div className="w-50">
                    <div className="mt-1">
                        <label className={`form-label fs-7 fw-bolder ${!!error?.enctype ? 'label-error' : ''}`}>
                            WIFI encryption type 5G
                        </label>                        
                        <select name="authtype5g" className="form-select" onChange={handleChange()} value={params?.authtype5g} >
                            {
                                router.wifiauthtypes?.map((item, key) => {
                                    return(                                        
                                        <option key={key} value={item.authstring}>{item.name}</option>
                                    )
                                })
                            }
                        </select>                        
                    </div>
                </div>  
                </div>
            </div>
            <div className="card-footer d-flex">
                {
                    wifiSettingsUpdating ?
                    <button 
                        type="button" 
                        className="btn btn-primary btn-sm"
                        disabled
                    >
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button> :
                    <button 
                        type="button" 
                        className="btn btn-primary btn-sm"
                        onClick={handleClickUpdate}
                        disabled={haveErrors}
                    >
                        Update Wifi settings
                    </button>
                }  
                {
                    routerActivatedUpdating ?
                    <button type="button" className="btn btn-warning ms-auto btn-sm" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button> :
                    <button type="button" className="btn btn-warning ms-auto btn-sm" onClick={handleClickRouterActivated}>
                        {router.detail.activated ? "Disable Router" : "Enable Router"}
                    </button>
                }              
                
            </div>
        </div>
    );
}

export default WifiSettingsCard;
