import React from "react";
import { useNavigate } from "react-router-dom"
import RootTable from "./RootTable";

const RoutersStuckTable = (props) => {

  const { items } = props
  const navigate = useNavigate()

  function handleClickShow(mac) {
    navigate('/router-detail', { state: { mac } })
  }

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">S/N</th>
          <th scope="col">MAC</th>
          <th scope="col">Last seen</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {
          items && items.map((item, key) => {
            return (
              <tr key={ key }>
                <th className="py-2 align-middle">{ item.serial_number }</th>
                <td className="py-2 align-middle">{ item.macaddr1 }</td>
                <td className="py-2 align-middle">{ '2022-05-11 11:00:00' }</td>
                <td className="py-2 align-middle">
                  <button 
                    type="button" 
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleClickShow(item.macaddr1)}
                  >
                    Show
                  </button>
                </td>
              </tr>  
            )
          })
        }              
      </tbody>
    </RootTable>
  );
}

export default RoutersStuckTable;