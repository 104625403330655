import React from "react";
import RootTable from "components/tables/RootTable";

const RouterStatisticTable = ({ stats }) => {

  const { TotalRouters: all, RoutersOnline: online, RoutersOffline: offline, RoutersActivated: activated, RoutersNotActivated: notActivated, Subscriptions: subscriptions, NewRoutersNumber: newrouters, OldRoutersNumber: oldrouters } = stats;
  const new_old = `${newrouters} / ${oldrouters}`;

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">All</th>
          <th scope="col">New/Old</th>
          <th scope="col">Online</th>
          <th scope="col">Offline</th>
          <th scope="col">Activated</th>
          <th scope="col">Not Activated</th>
          <th scope="col">Subscriptions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-2 align-middle">{all}</td>
          <td className="py-2 align-middle">{new_old}</td>
          <td className="py-2 align-middle">{online}</td>                
          <td className="py-2 align-middle">{offline}</td>
          <td className="py-2 align-middle">{activated}</td>
          <td className="py-2 align-middle">{notActivated}</td>
          <td className="py-2 align-middle">{subscriptions}</td>
        </tr>  
      </tbody>
    </RootTable>
  );
}

export default RouterStatisticTable;