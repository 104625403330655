import { useEffect, useState } from "react"

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value]);
  
    return debouncedValue;
}

export default function useCheckDomain({ onValid, onInvalid }) {
    const [value, setDomain] = useState(null);
    const [processing, setProcessing] = useState(false);

    const domain = useDebounce(value, 2000);

    useEffect(() => {
        if (!domain || domain == '') {
            setProcessing(false);
            return;
        }

        const controller = new AbortController();
        setProcessing(true);

        fetch(`https://dns.google/resolve?name=${domain}`)
        .then(response => response.json())
        .then(data => data?.Status === 0 ? onValid(domain) : onInvalid(domain))
        .catch(error => {
            setProcessing(false);
            onInvalid(domain);
        })
        .finally(() => setProcessing(false));

        return () => controller.abort();

    }, [domain]);

    const checkDomain = domain => setDomain(domain);

    return {
        checkDomain,
        processing
    }
}