import React from "react";
import RootTable from "./RootTable";


const Item = ({ item }) => {

  const { Server: server, RoutersAssigned: assigned, RoutersOnline: online } = item;
  const name = `${server.CountryShortName} - ${server.Description} (${server.ServerIP})`;

  return (
    <tr>
      <td className="py-2 align-middle">{name}</td>
      <td className="py-2 align-middle">{assigned}</td>                
      <td className="py-2 align-middle">{online}</td>
    </tr>
  );
}

const LocationStatisticTable = ({ items }) => {

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">Location</th>
          <th scope="col">Assigned</th>
          <th scope="col">Online</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, key) => <Item {...{key, item}} />)}        
      </tbody>
    </RootTable>
  );
}

export default LocationStatisticTable;