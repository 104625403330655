import React from "react";
import roles, { labels } from "utils/roles";

export default ({ onChange, value }) => {

    return (
        <select name="role" className="form-select" {...{onChange: e => onChange && onChange(e), value}} >
            {roles.map(role => <option value={role}>{labels[role]}</option>)}
        </select>
    );
}