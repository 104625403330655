import React, { useState } from "react";
import { connect } from 'react-redux';
import { addMeshStart } from 'store/routers/routersActions';
import MeshTable from 'components/tables/MeshTable'

const MeshCard = (props) => {

    const { mac1, addMeshStart } = props
    const { currentUser } = props.auth
    const { router } = props.routers

    const [mac2, setMac2] = useState('');
    const [validateError, setValidateError] = useState(false);

    function validate(value) {
        if(value === "")
            return false
        
        var regex = /^[0-9a-f]{1,}([\.:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/;
        if (!regex.test(value)){
            return false
        }

        return true
    }

    const handleChange = (e) => {
        setMac2(e.target.value)
        setValidateError(!validate(e.target.value))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const payload = {
            token: currentUser.token,
            mac1
        }

        addMeshStart(payload)
        setMac2('')
    }

    return (
        <div className="card mt-1">
            <div className="card-header">
                Mesh
            </div>
            <div className="card-body">
                <MeshTable 
                    items={ router.meshes }
                />
            </div>
            <div className="card-footer">
                <form className="d-flex" onSubmit={handleSubmit}>
                    <input 
                        type="text" 
                        className="form-control me-1" 
                        placeholder="MAC" 
                        value={mac2}
                        onChange={handleChange}
                    />
                    <button 
                        type="submit" 
                        className="btn btn-primary btn-sm"
                        disabled={mac2.length === 0 || validateError}
                    >
                        Add
                    </button>
                </form>
                {
                    validateError &&
                    <span className="text-danger">Mac is invalid</span>
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    routers: state.routers   
})
  
const mapDispatchToProps = {
    addMeshStart
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MeshCard)
