import { all, call, put, takeLatest } from 'redux-saga/effects';
import { decodeToken } from "react-jwt";
import { 
  logInFailure, 
  logInSuccess, 
} from './authActions';
import types from './authActionTypes';

const logIn = async (email, password) => {

  var payload = {
    'login': email,
    'password': password,      
  };

  var formBody = [];
  for (var property in payload) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(payload[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  const rootUrl = process.env.REACT_APP_API1_HOST_URL;
  
  const response = await fetch(`${rootUrl}/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },   
    body: formBody
  })

  const data = await response.json()  
  const myDecodeToken = decodeToken(data.jwt);

  return { 
    email, 
    token: data.jwt, 
    accesslevel: myDecodeToken.accesslevel 
  };
};

export function* logInWithCredentials({ payload: { email, password } }) {
  try {
    const user = yield logIn(email, password);
    yield put(logInSuccess(user));
  } catch (error) {
    yield put(logInFailure(error));
  }
}

export function* onLogInStart() {
  yield takeLatest(types.LOG_IN_START, logInWithCredentials);
}

export function* authSagas() {
  yield all([
    call(onLogInStart)
  ]);
}
