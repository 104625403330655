import React from "react";
import RootTable from "./RootTable";

export const BYPASS_PREFIX_MAC = 'bypass_mac_address_';

const ConnectedClientsTable = ({ router, devices, onEnableBypass, onDisableBypass }) => {

  const bypass = (router.extraparams?.domain_pass?.split(",") || [])
    .filter(pass => pass.startsWith(BYPASS_PREFIX_MAC))
    .map(pass => pass.substr(BYPASS_PREFIX_MAC.length));

  const items = [...(devices?.hosts?.online || []).map(i => ({...i, online: true})), ...(devices?.hosts?.offline || [])]
    .map(i => ({...i, ...(bypass.includes(i.mac) ? {bypass: true} : {})}));

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">MAC</th>
          <th scope="col">Hostname</th>
          <th scope="col">IP</th>
          <th scope="col">Online</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          items && items?.map((item, key) => {
            return(
              <tr key={ key }>
                <td className="py-2 align-middle">{item.mac}</td>
                <td className="py-2 align-middle">{item.name}</td>                
                <td className="py-2 align-middle">{item.ip}</td>
                <td className="py-2 align-middle">
                  {item?.online ?  
                    <span className="badge bg-success">Online</span> :
                    <span className="badge bg-danger">Offline</span> 
                  }
                </td>
                <td>
                  <button type="button" className={`btn btn-sm ${item.bypass ? 'btn-danger' : 'btn-primary'}`} onClick={() => item.bypass ? onDisableBypass(item.mac) : onEnableBypass(item.mac)}>
                  {item.bypass ? "Disable bypass": "Enable bypass" }
                  </button>
                </td>
              </tr>  
            )
          })
        }       
      </tbody>
    </RootTable>
  );
}

export default ConnectedClientsTable;