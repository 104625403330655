import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStart } from 'store/dashboard/dashboardActions';
import AgentStatistic from './components/AgentStatistic';
import RouterStatistic from './components/RouterStatistic';
import LocationStatistic from './components/LocationStatistic';
import macSerialInputFilter from 'utils/macSerialInputFilter';
import useAccessLevel from 'utils/useAccessLevel';
import { useNavigate } from 'react-router-dom';
import Content from 'components/Content';
import './styles.css';

const Dashboard = (props) => {

    const { currentUser } = useSelector(state => state.auth);
    const { data, loading, error } = useSelector(state => state.dashboard);
    const accesslevel = useAccessLevel();
    const dispatch = useDispatch();
    // const { statistic } = props.dashboard

    useEffect(() => {
        dispatch(getDashboardStart(currentUser.token));
    }, [currentUser, getDashboardStart]);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!data) {
        return <div>No data</div>
    }

    return (
        <div className="row">
            <div className="col-lg-6 col-md-12 mt-3">
                <RouterStatistic {...{data}} />
                <LocationStatistic {...{data}} />
            </div>
            <div className="col-lg-6 col-md-12 mt-3">
                <AgentStatistic {...{data}} />
            </div>
        </div>
    );

};

export default ({ handleShowSideBar }) => {
    const navigate = useNavigate();
    const handleSearch = ({ searchKey: search }) => navigate(`/routers?search=${search}`);

    return (
        <Content {...{handleShowSideBar, handleSearch, searchPlaceholder: "Mac or S/N", showSearch: true, inputFilter: macSerialInputFilter}}>
            <Dashboard />
        </Content>
    );
}
