import React from "react";
import { connect } from 'react-redux';
import { deleteMeshStart } from 'store/routers/routersActions';
import RootTable from "./RootTable";

const MeshTable = (props) => {

  const { items, deleteMeshStart } = props
  const { currentUser } = props.auth

  function handleClick(mac) {

    const payload = {
      token: currentUser.token,
      mac
    }

    deleteMeshStart(payload)
  }

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">MAC</th>
          <th scope="col">Added</th>
          <th scope="col">Discovered</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((item, key) => {
            return(
              <tr key={ key }>
                <td className="py-2 align-middle">{ item.macaddr1 }</td>
                <td className="py-2 align-middle">{ item.added }</td>                
                <td className="py-2 align-middle">{ item.discovered }</td>
                <td className="py-2 align-middle">
                  <button 
                    type="button" 
                    className="btn btn-danger btn-sm"
                    onClick={() => handleClick(item.macaddr1)}
                  >
                    Delete
                  </button>
                </td>
              </tr>  
            )
          })
        }       
      </tbody>
    </RootTable>
  );
}


const mapStateToProps = state => ({
  auth: state.auth,
  routers: state.routers   
})

const mapDispatchToProps = {
  deleteMeshStart
}

export default connect(mapStateToProps, mapDispatchToProps)(MeshTable)
