import React from "react";
import RouterStatisticTable from "./RouterStatisticTable";

export default ({ data }) => {

    const { OverallRoutersStats: stats } = data || {};

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="fw-bold">Router Statistic</h5>
                <h6 className="fw-semibold text-secondary">Update at: {stats.UpdateTime}</h6>
            </div>
            <div className="card-body">
                <RouterStatisticTable {...{stats}} />
            </div>
        </div>
    );
}