import React from "react";
import RootTable from "components/tables/RootTable";

const AgentStatisticTable = (props) => {

  const { items } = props

  return (
    <RootTable>
      <thead>
        <tr>
          <th scope="col">Agent</th>
          <th scope="col">Today</th>
          <th scope="col">This Week</th>
          <th scope="col">This Month</th>
          <th scope="col">This Year</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((item, key) => {
            return(
              <tr key={ key }>
                <td className="py-2 align-middle">{ item.agent }</td>
                <td className="py-2 align-middle">{ item.today }</td>                
                <td className="py-2 align-middle">{ item.thisWeek }</td>
                <td className="py-2 align-middle">{ item.thisMonth }</td>
                <td className="py-2 align-middle">{ item.thisYear }</td>
                <td className="py-2 align-middle">{ item.total }</td>
              </tr> 
            )
          })
        }         
      </tbody>
    </RootTable>
  );
}

export default AgentStatisticTable;