import { all, call, put, takeLatest } from 'redux-saga/effects';
import { registerFailure, registerSuccess, getUsersSuccess, getUsersFailure, searchUsersSuccess, searchUsersFailure, updateUserSuccess, updateUserFailure, resetPasswordSuccess, resetPasswordFailure } from './actions';
import { createUserAPI, getUsersAPI, resetPasswordAPI, searchUsersAPI, updateUserAPI } from './api';
import types from './types';


export function* updateUser({ payload: { token, id, params }}) {
    try {
        const items = yield updateUserAPI(token, {id, params});
        yield put(updateUserSuccess(items));
    } catch (error) {
        yield put(updateUserFailure(error));
    }
}

export function* getUsers({ payload: { token } }) {
  try {
    const items = yield getUsersAPI(token);
    yield put(getUsersSuccess(items));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

export function* searchUsers({ payload: { token, searchKey } }) {
  try {
    const items = yield searchUsersAPI(token, searchKey);
    yield put(searchUsersSuccess(items));
  } catch (error) {
    yield put(searchUsersFailure(error));
  }
}

export function* registerWithCredentials({ payload: { token, credentials } }) {
  try {
    const items = yield createUserAPI(token, credentials);
    yield put(registerSuccess(items));
  } catch (error) {
    yield put(registerFailure(error));
  }
}

export function* resetPassword(params) {
  const { payload: { token, id }} = params;
  try {
    const { password } = yield resetPasswordAPI(token, id);
    yield put(resetPasswordSuccess({password}));
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export function* onUpdateStart() {
    yield takeLatest(types.UPDATE_USER_START, updateUser);
}

export function* onRegisterStart() {
  yield takeLatest(types.REGISTER_START, registerWithCredentials);
}

export function* onGetUsersStart() {
  yield takeLatest(types.GET_USERS_START, getUsers);
}

export function* onSearchUsersStart() {
  yield takeLatest(types.SEARCH_USERS_START, searchUsers);
}

export function* onResetPasswordStart() {
  yield takeLatest(types.RESET_PASSWORD_START, resetPassword);  
}

export function* sagas() {
  yield all([
    call(onRegisterStart),
    call(onUpdateStart),
    call(onGetUsersStart),
    call(onResetPasswordStart),
    call(onSearchUsersStart)
  ]);
}
