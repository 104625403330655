import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  getDashboardSuccess, 
  getDashboardFailure, 
} from './dashboardActions';
import types from './dashboardActionTypes';

const getDashboardAPI = async (token) => {
  const url = process.env.REACT_APP_STATS_API_URL;
  if (!url) {
    return;
  }

  const response = await fetch(`${url}/stats`, {
    method: 'GET',
    headers: {
      "Authorization": `BEARER ${token}`,
      "Content-Type": 'application/json'
    }
  })

  const data = await response.json()   
  return data;
};

export function* getDashboard({ payload: { token } }) {
  try {
    const data = yield getDashboardAPI(token);
    yield put(getDashboardSuccess(data));
  } catch (error) {
    yield put(getDashboardFailure(error));
  }
}

export function* onGetDashboardStart() {
  yield takeLatest(types.GET_DASHBOARD_START, getDashboard);
}

export function* dashboardSagas() {
  yield all([
    call(onGetDashboardStart)
  ]);
}
