import types from './routersActionTypes';

const INITIAL_STATE = {
  routers: null,
  devices: null,
  stuckRouters: null,
  router: null,  
  error: null,
  wifiSettingsUpdating: false,
  appCodeAdding: false,
  routerActivatedUpdating: false,
  meshAdding: false,
  rebooting: false,
  subscriptionUpdating: false,
  activatedAppDeleting: false, 
};

const routersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SUBSCRIPTION_UPDATE_START:
      return {
        ...state,
        subscriptionUpdating: true
      }
    case types.REBOOT_START:
      return {
        ...state,
        rebooting: true
      }
    case types.REMOVE_ROUTERS_START:
      return {
        ...state,
        routers: null
      }
    case types.REMOVE_STUCK_ROUTERS_START:
      return {
        ...state,
        stuckRouters: null
      }  
    case types.ADD_MESH_START:
      return {
        ...state,
        meshAdding: true
      }
    case types.UPDATE_ROUTER_ACTIVATED_START:
      return {
        ...state,
        routerActivatedUpdating: true
      }
    case types.ADD_APP_CODES_START:
      return {
        ...state,
        appCodeAdding: true
      }
    case types.UPDATE_WIFI_SETTINGS_START:
      return {
        ...state,
        wifiSettingsUpdating: true
      }
    case types.DELETE_ACTIVATED_APP_START:
      return {
        ...state,
        activatedAppDeleting: true
      }
    case types.GET_ROUTER_DETAIL_SUCCESS:
    case types.UPDATE_WIFI_SETTINGS_SUCCESS:
    case types.UPDATE_ROUTER_ACTIVATED_SUCCESS:
    case types.ADD_MESH_SUCCESS:
    case types.DELETE_MESH_SUCCESS:
    case types.ADD_DOMAIN_SUCCESS:
    case types.DELETE_DOMAIN_SUCCESS:
    case types.UPDATE_VPN_STATUS_SUCCESS:
    case types.UPDATE_VPN_LOCATION_SUCCESS:
    case types.ADD_APP_CODES_SUCCESS:
    case types.REBOOT_SUCCESS:
    case types.SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        router: action.payload,
        wifiSettingsUpdating: false,
        routerActivatedUpdating: false,
        meshAdding: false,
        appCodeAdding: false,
        rebooting: false,
        subscriptionUpdating: false,
        activatedAppDeleting: false,
        error: null,
      };    
    case types.SEARCH_ROUTERS_SUCCESS:
      return {
        ...state,
        routers: action.payload,
        error: null,
      };
    case types.SEARCH_STUCK_ROUTERS_SUCCESS:
      return {
        ...state,
        stuckRouters: action.payload,
        error: null,
      };       
    case types.GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload
      };      
    case types.GET_ROUTER_DETAIL_FAILURE:
    case types.UPDATE_WIFI_SETTINGS_FAILURE:
    case types.ADD_APP_CODES_FAILURE:
    case types.UPDATE_ROUTER_ACTIVATED_FAILURE:
    case types.SEARCH_ROUTERS_FAILURE:
    case types.ADD_DOMAIN_FAILURE:
    case types.DELETE_DOMAIN_FAILURE:
    case types.UPDATE_VPN_STATUS_FAILURE:
    case types.DELETE_MESH_FAILURE:
    case types.UPDATE_VPN_LOCATION_FAILURE:
    case types.REBOOT_FAILURE:
    case types.SUBSCRIPTION_UPDATE_FAILURE:
    case types.DELETE_ACTIVATED_APP_FAILURE:
      return {
        ...state,
        error: action.payload,
        wifiSettingsUpdating: false,
        appCodeAdding: false,
        routerActivatedUpdating: false,
        meshAdding: false,
        rebooting: false,
        subscriptionUpdating: false,
        activatedAppDeleting: false,
      };
    case types.INITIAL_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default routersReducer;
