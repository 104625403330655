import types from './dashboardActionTypes';

export const InitialState = () => ({
  type: types.INITIAL_STATE,
});

export const getDashboardStart = (token) => ({
  type: types.GET_DASHBOARD_START,
  payload: { token },
});

export const getDashboardSuccess = (data) => ({
  type: types.GET_DASHBOARD_SUCCESS,
  payload: data,
});

export const getDashboardFailure = (error) => ({
  type: types.GET_DASHBOARD_FAILURE,
  payload: error,
});
