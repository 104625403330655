import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLE_ADMIN, ROLE_SUPPORT_ADMIN, ROLE_SYS_ADMIN } from 'utils/roles';
import AccountsTable from './components/AccountsTable';
import { searchUsersStart } from './store/actions';
import useAccessLevel from 'utils/useAccessLevel';
import useCurrentUser from 'utils/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import AddUser from './components/AddUser';
import Content from 'components/Content';
import './styles.css';

const Accounts = ({ handleShowSideBar }) => {

  const currentUser = useCurrentUser();
  const accesslevel = useAccessLevel();
  const navigate = useNavigate()

  const [showDisabled, setShowDisabled] = useState(true)

  const dispatch = useDispatch();
  const handleSearch = params => dispatch(searchUsersStart(params));

  useEffect(() => {
    if (![ROLE_ADMIN, ROLE_SYS_ADMIN, ROLE_SUPPORT_ADMIN].includes(accesslevel)) {
      navigate('/');
    }
  }, [accesslevel]);

  if (![ROLE_ADMIN, ROLE_SYS_ADMIN, ROLE_SUPPORT_ADMIN].includes(accesslevel)) {
   return null;
  }

  const addUserEnable = +accesslevel <= +ROLE_ADMIN;

  return (
    <Content {...{searchPlaceholder:"Name or Email", showSearch: true, handleSearch, handleShowSideBar}}>
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="card">
            <div className="card-header">
              <span className="fs-5 fw-bold">Accounts</span>
              <button type="button" className="btn btn-secondary btn-sm ms-3" onClick={() => setShowDisabled(!showDisabled)}>
                { !showDisabled? "Show disabled": "Hide disabled" }
              </button>
            </div>
            <div className="card-body">
              <AccountsTable {...{showDisabled}} />
            </div>
          </div>        
        </div>
        <div className="col-lg-4 col-md-12">
          {addUserEnable && <AddUser />}
        </div>
      </div>  
    </Content>
  );
};


export default Accounts;
